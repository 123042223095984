import React, {useEffect, useState} from "react";
import {Outlet, useParams} from "react-router-dom";
import {Container, CustomLink, Image, Section} from "components";
import {DetailedProject as DetailedProjectModel, PaymentSchedule} from "api-client";
import {createPaymentScheduleProvider, createProjectProvider} from "di";
import {useErrorCallback, useLoading} from "hooks";
import './BorrowerDetailedProject.scoped.scss';
import DetailedProjectContextProvider
  from "../../../../components/pages/detailed-project/DetailedProjectContext/DetailedProjectContext";
import {RouteDictionary} from "../../../../utils";
import arrowBack from "../../../../assets/images/svg/header_user-arrow.svg";
import ProjectMainBorrower from "../../../../components/pages/detailed-project/ProjectMainBorrower";

const BorrowerDetailedProject = () => {
  const {uuid} = useParams();
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const [project, setProject] = useState<DetailedProjectModel | null>(null);
  const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule | null>(null);
  useEffect(() => {
    const fetchProjectAndSchedule = async () => {
      try {
        const provider = await createProjectProvider();
        const project = await provider.getOne(uuid!);
        setProject(project);
        if (project) {
          const manager = await createPaymentScheduleProvider();
          const result = await manager.getPaymentSchedule(project.uuid);
          setPaymentSchedule(result);
        }
      } catch (err: any) {
        await errorCallback(err);
        setProject(null);
      }
    };

    (async () => {
      await withLoading(fetchProjectAndSchedule);
    })();
  }, [uuid]);
  return (
    <Container>
      <Section>
        { loading && <div>Загрузка...</div> }
        { !loading && project === null && <div>Проект не найден</div> }
        { project && <DetailedProjectContextProvider props={{project: project, paymentSchedule: paymentSchedule}}>
          <CustomLink className="detailed-project-back" to={RouteDictionary.BORROWER_PROJECTS}>
            <Image src={arrowBack}/>
          </CustomLink>
          <div className="detailed-project">
            <ProjectMainBorrower paymentSchedule={paymentSchedule}/>
            <div className="detailed-project-outlet">
              {/*<DetailedProjectMobileNavigation/>*/}
              <Outlet/>
            </div>
          </div>
        </DetailedProjectContextProvider> }
      </Section>
    </Container>
  );
};

export default BorrowerDetailedProject;
import React, {FC} from "react";
import Modal from "../Modal";
import SmsConfirmation from "../../../confirmation/SmsConfirmation";
import {ModalProps} from "../Modal/Modal";

interface Props extends ModalProps {
  isLegalEntity?: boolean
}
export const VerificationConfirmationModal: FC<Props> = ({active, onClose, isLegalEntity = false}) => {
  return (
    <Modal active={active} onClose={onClose}>
      <SmsConfirmation header="Подтверждение верификации" onClose={onClose}>

        {isLegalEntity ?
          <div className="confirm-form">
            <div className="confirm-form__controls">
              Вам на почту будет направлен Документ – Заверение об обстоятельствах, который Вам необходимо подписать с помощью УКЭП юридического лица.
            </div>
            <div className="confirm-form__controls">
              Уведомление о доступе к функционалу инвестирования будет направлено вам на указанный вами адрес электронной почты после проведения Оператором проверки предоставленных данных на соответствие требованиям законодательства и при условии действительности правового основания действовать в качестве представителя указанного вами юридического лица.
            </div>
          </div> :
          <div className="confirm-form">
            <div className="confirm-form__controls">
              Доступ к функционалу Платформы будет предоставлен после проведения Оператором проверки предоставленных
              данных на соответствие требованиям законодательства.
            </div>
            <div className="confirm-form__controls">
              Уведомление о доступе к функционалу инвестирования будет направлено вам на указанный вами адрес
              электронной почты.
            </div>
          </div>
        }

      </SmsConfirmation>
    </Modal>
  );
};


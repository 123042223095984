import React, {FC, useContext, useState} from "react";
import {Errors as ValidationErrors, isEmail, numbersOnly} from "../../../../../utils";
import {IdentificationBlock} from "../index";
import {CommonInput, Heading, InputErrors, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {Identification} from "../../../../../api-client";
import IdentificationContext from "../../../../context/IdentificationContext";
import {useEffectOnMount, useEffectOnUpdate, useProfileData} from "../../../../../hooks";
import * as constants from 'utils/constants';
import {ProfileState} from "../../../../../store/profile";
import {CombinedDataContext} from "../../../../../hooks/identification/useCombinedData";

export type IdentificationPersonalErrors = {
    firstName?: ValidationErrors;
    middleName?: ValidationErrors;
    lastName?: ValidationErrors;
    inn?: ValidationErrors;
    ogrnip?: ValidationErrors;
    snils?: ValidationErrors;
    email?: ValidationErrors;
    jobPosition?: ValidationErrors;
    innLegalEntity?: ValidationErrors;
}

interface Props {
    isLegalEntity?: boolean;
    onValidated: (value: boolean) => void;
    identification: Identification | undefined
}

export const IdentificationPersonalBorrower: FC<Props> = ({onValidated, identification}) => {
  const [errors, setErrors] = useState<IdentificationPersonalErrors>({});
  const context: CombinedDataContext = useContext(IdentificationContext);
  const profile: ProfileState = useProfileData();
  const isVerified = () => {
    return profile.lenderVerified;
  };
  useEffectOnMount(() => {
    const person = identification?.person;
    if (identification && person) {
      context.setFirstName(person.firstName);
      context.setMiddleName(person.middleName);
      context.setSnils(person.snils);
      context.setInn(identification.inn.replaceAll(' ', ''));
      if (context.lastName) context.setLastName(person.lastName ?? '');

      if (profile.email) {
        context.setEmail(profile.email.email);
      }
      if (identification.ogrnip) {
        context.setOgrnip(identification.ogrnip);
      }
      if (identification.jobPosition) {
        context.setJobPosition(identification.jobPosition);
      }
      if (identification.innLegalEntity) {
        context.setInnLegalEntity(identification.innLegalEntity);
      }
    }
  });

  const validateBlock = (): boolean => {
    const errors: IdentificationPersonalErrors = {};
    if (context.firstName === "") {
      errors.firstName = ["Имя обязательно для заполнения"];
    }
    if (context.lastName === "") {
      errors.lastName = ["Фамилия обязательна для заполнения"];
    }
    if (context.inn === "") {
      errors.inn = ["ИНН обязателен для заполнения"];
    } else if (!numbersOnly(context.inn)) {
      errors.inn = ["ИНН должен состоять из цифр"];
    }
    if (context.inn.length !== constants.INN_INDIVIDUAL_LENGTH) {
      errors.inn = [`Длина ИНН - ${constants.INN_INDIVIDUAL_LENGTH} символов`];
    }
    if (context.snils === "") {
      errors.snils = ["СНИЛС обязателен для заполнения"];
    } else if (!numbersOnly(context.snils)) {
      errors.snils = ["СНИЛС должен состоять из цифр"];
    } else if (context.snils.length !== constants.SNILS_LENGTH) {
      errors.snils = [`Длина СНИЛС - ${constants.SNILS_LENGTH} символов`];
    }
    if (context.email === "") {
      errors.email = ["Email обязателен для заполнения"];
    } else if (!isEmail(context.email)) {
      errors.email = ["Неверный формат email"];
    }
    if (context.jobPosition === "") {
      errors.jobPosition = ["Должность в юр. лице обязательна для заполнения"];
    }
    if (context.innLegalEntity === "") {
      errors.innLegalEntity = ["ИНН обязателен для заполнения"];
    } else if (!numbersOnly(context.innLegalEntity)) {
      errors.innLegalEntity = ["ИНН должен состоять из цифр"];
    } else if (context.innLegalEntity.length !== constants.INN_INDIVIDUAL_LENGTH) {
      errors.innLegalEntity = [`Длина ИНН - ${constants.INN_INDIVIDUAL_LENGTH} символов`];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    onValidated(validateBlock());
  }, [
    context.middleName,
    context.firstName,
    context.lastName,
    context.inn,
    context.phone,
    context.ogrnip,
    context.snils,
    context.email,
    context.jobPosition,
    context.innLegalEntity,
  ]);
  return (
    <>
      <IdentificationBlock>
        <SecondaryHeading>
          <Heading headingType={HeadingType.H2}>
            Персональные данные представителя юр. лица
          </Heading>
        </SecondaryHeading>
        <div className="form__group">
          <CommonInput
            value={context.lastName}
            onChange={(value) => context.setLastName(value)}
            title="Фамилия"
            id="lastName"
            required
          />
          {errors.lastName && <InputErrors errors={errors.lastName} className="identification__input_errors"/>}
        </div>
        <div className="form__group">
          <CommonInput
            value={context.firstName}
            onChange={(value) => context.setFirstName(value)}
            title="Имя"
            id="firstName"
            required
          />
          {errors.firstName && <InputErrors errors={errors.firstName} className="identification__input_errors"/>}
        </div>
        <div className="form__group">
          <CommonInput
            value={context.middleName}
            onChange={(value) => context.setMiddleName(value)}
            title="Отчество"
            id="middleName"
            required={false}
          />
          {errors.middleName && <InputErrors errors={errors.middleName} className="identification__input_errors"/>}
        </div>
        {!isVerified() &&
        <div className="form__group">
          <CommonInput
            value={context.email}
            onChange={(value) => context.setEmail(value)}
            title="Email"
            id="email"
            required
            disabled={profile.lenderVerified}
          />
          {errors.email && <InputErrors errors={errors.email} className="identification__input_errors"/>}
        </div>
        }
        <div className="form__group">
          <CommonInput
            value={context.inn}
            onChange={(value) => context.setInn(value)}
            title="ИНН"
            id="inn"
            required
          />
          {errors.inn && <InputErrors errors={errors.inn} className="identification__input_errors"/>}
        </div>
        <div className="form__group">
          <CommonInput
            value={context.snils}
            onChange={(value) => context.setSnils(value)}
            title="СНИЛС"
            id="snils"
            required
          />
          {errors.snils && <InputErrors errors={errors.snils} className="identification__input_errors"/>}
        </div>
        <div className="form__group">
          <CommonInput
            value={context.jobPosition}
            onChange={(value) => context.setJobPosition(value)}
            title="Должность в юр. лице"
            id="jobPosition"
            required
          />
          {errors.jobPosition && <InputErrors errors={errors.jobPosition} className="identification__input_errors"/>}
        </div>
      </IdentificationBlock>
      <IdentificationBlock>
        <SecondaryHeading>
          <Heading headingType={HeadingType.H2}>
            Данные юридического лица
          </Heading>
        </SecondaryHeading>
        <CommonInput
          value={context.innLegalEntity}
          onChange={(value) => context.setInnLegalEntity(value)}
          title="ИНН"
          id="innLegalEntity"
          required
        />
        {errors.innLegalEntity && <InputErrors errors={errors.innLegalEntity} className="identification__input_errors"/>}
      </IdentificationBlock>
    </>
  );
};
import {Image} from "../../../../../../../components";
import documentIcon from "../../../../../../../assets/images/svg/document.svg";
import FileSize from "../../../../../../../components/common/utils/FileSize/FileSize";
import {formatDate} from "../../../../../../../formatters";
import React from "react";
import styles from '../DocumentsToProjectsItem.module.scss';
import {ProjectDocument} from "../../../../../../../api-client";


interface Props {
    document: ProjectDocument,
}

export const DifferentDocument = ({document}: Props) => {
  return (
    <ul className={styles.documentsToProjectBody__list}>
      <li>
        <a href={document.file.url} className={styles.documentsToProjectBody__item}
          target="_blank" rel="noreferrer">
          <Image className={styles.documentsToProjectBody__icon}
            src={documentIcon}/>
          <div
            className={styles.documentsToProjectBody__title}>{document.file.name}
          </div>
          <div
            className={styles.documentsToProjectBody__size}>pdf, <FileSize file={document.file}/></div>
          <a className={styles.documentsToProjectBody__watch}>Нажмите, чтобы посмотреть</a>
          <div
            className={styles.documentsToProjectBody__date}>{formatDate(document.publicationDate)}</div>
          <div
            className={styles.documentsToProjectBody__dateText}>дата
            публикации
          </div>
        </a>
      </li>
    </ul>
  );
};
import React, {useCallback, useState} from "react";
import {useErrorCallback} from "hooks";
import {DataCollection, RouteDictionary} from "utils";
import {OwnedProject} from "api-client";
import {Block, CustomLink, Pagination, Section, Container} from "components";
import './MyOwnedProjects.scoped.scss';
import {createProjectProvider} from "../../../../di";
import MyOwnedProject from "../../../../components/pages/projects/MyOwnedProject";

export interface AutoInvestmentFilter {
  projectUuids?: Array<string>
}

const MyOwnedProjects = () => {
  const handleError = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] =
        useState<DataCollection<OwnedProject>>(new DataCollection());

  const loadData = useCallback(async (page: number, perPage: number): Promise<void> => {
    setLoading(true);
    try {
      const provider = await createProjectProvider();
      const projects = await provider.getOwned(page, perPage);
      const dataCollection = new DataCollection<OwnedProject>();
      dataCollection.setCollection(projects);
      setProjects(dataCollection);
    } catch (err) {
      if (err instanceof Error) await handleError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const projectsList = useCallback(() => {
    return projects.items.map((item: OwnedProject) => {
      return <CustomLink to={RouteDictionary.BORROWER_DETAILED_PROJECT} params={{uuid: item.uuid}} key={item.uuid} className="my-owned-projects-link">
        <MyOwnedProject project={item} key={item.uuid}/>
      </CustomLink>
      ;
    });
  }, [projects]);
  return (
    <Container>
      <Section>
        <Pagination
          onLoad={loadData}
          count={projects.count}
          totalCount={projects.totalCount}
        >
          <Block>
            <Block.Content>
              <Block.Header className="my-owned-projects-block-header" withPadding>
              </Block.Header>
              <Block.Body className="my-owned-projects-block-body" withPadding={false}>
                <Pagination.Content loading={loading}>
                  <ul className="my-owned-projects__list">
                    {projectsList()}
                  </ul>
                </Pagination.Content>
              </Block.Body>
            </Block.Content>
          </Block>
          <Pagination.Paginator/>
        </Pagination>
      </Section>
    </Container>
  );
};

export default MyOwnedProjects;
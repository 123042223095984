import React, {memo, useEffect} from "react";
import "./Header.scoped.scss";
import {CustomLink, FAQLink, Image, TertiaryButton} from "components/common";
import {useLoggedInStatus, useProfileData, useToggle} from "hooks";
import {Hamburger, SecondaryButton} from "../../common";
import {Container, Navigation} from "../index";
import {SecondaryButtonColor} from "../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import ProfileMenu from "../../user/profile/ProfileMenu/ProfileMenu";
import {RouteDictionary} from "../../../utils";
import {LogoImage} from "../../common/images/types";
import {LogoType} from "../../common/images/types/LogoImage";
import NotificationLink from "../../common/buttons/decorators/NotificationLink";
import MobileNavigation from "../MobileNavigation";
import {TertiaryButtonColor} from "../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import {useWindowWidth} from "../../pages/detailed-project/hooks";
import cross from "assets/images/svg/profile-menu-cross.svg";
import {Role} from "../../../api-client";
import {Link} from "react-router-dom";


type Props = {
    isPublic?: boolean;
}

const Header = ({isPublic}: Props) => {
  const [active, toggle, setActive] = useToggle();
  const profileData = useProfileData();
  const isLoggedIn = useLoggedInStatus();
  const isMobile = useWindowWidth(992);
  const {role} = useProfileData();
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [active]);
  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      setActive(false);
    }
  };
  return (
    <div className={`header__wrapper ${active ? "header__wrapper__active" : ''}`} onClick={handleWrapperClick}>
      <header className="header">
        <Container>
          <nav className="header__navigation">
            <div className="header__navigation__left">
              {
                isLoggedIn && isMobile ? <><ProfileMenu clickable={false}/>
                  <div className="header__mobile__notifications">
                    <NotificationLink/>
                    {profileData.newNotificationCount > 0 &&
                    <span
                      className="header__mobile__notifications__notification-count">{profileData.newNotificationCount}</span>}
                  </div>
                </> : <div className="header__logo-container">
                  <CustomLink className="index-link" to={RouteDictionary.INDEX}>
                    <LogoImage className="header__logo" type={LogoType.GREEN}/>
                  </CustomLink>
                </div>
              }
              {isPublic && <div className="header__projects">
                <CustomLink to={RouteDictionary.BORROWER_INFORMATION} className="header__projects-link">
                  <div>
                    Заемщикам
                  </div>
                </CustomLink>
                <CustomLink to={RouteDictionary.INVESTORS_INFORMATION} className="header__projects-link">
                  <div>
                    Инвесторам
                  </div>
                </CustomLink>
                <CustomLink to={RouteDictionary.ABOUT_US} className="header__projects-link">
                  <div>
                    Раскрытие информации
                  </div>
                </CustomLink>
                <CustomLink to={RouteDictionary.NEWS} className="header__projects-link">
                  <div>
                    Новости
                  </div>
                </CustomLink>
              </div> }
            </div>
            <div className="header__navigation__right">
              { !isLoggedIn ? <div className="header__auth-menu">
                Войти как
                <SecondaryButton color={SecondaryButtonColor.GREEN}>
                  <Link to={`${RouteDictionary.ENTER}/${Role.LENDER.toLowerCase()}`} >
                    Инвестор
                  </Link>
                </SecondaryButton>
                или
                <SecondaryButton color={SecondaryButtonColor.ORANGE}>
                  <Link to={`${RouteDictionary.ENTER}/${Role.BORROWER.toLowerCase()}`}>
                    Заемщик
                  </Link>
                </SecondaryButton>
              </div> : <div className="header__user-menu">
                <div className="header__notifications">
                  <NotificationLink/>
                  {profileData.newNotificationCount > 0 &&
                  <span
                    className="header__notifications__notification-count">{profileData.newNotificationCount}</span>}
                </div>
                {role === Role.BORROWER && <div className="header__notifications">
                  <FAQLink/>
                </div>}
                <div className="header__profile">
                  <ProfileMenu/>
                </div>
              </div>}
              <div className="header__mobile-open">
                {active ? <Image src={cross} onClick={toggle} className="header__mobile-open__cross"/> : <Hamburger onClick={toggle} rows={3}/>}
              </div>
            </div>
          </nav>
          {isLoggedIn && <>
            {active &&  <div className="mobile-navigation__money">
              {role === Role.LENDER && <TertiaryButton expanded color={TertiaryButtonColor.GREEN}>
                <CustomLink to={RouteDictionary.DEPOSIT}>
                  Пополнить
                </CustomLink>
              </TertiaryButton>}
              <TertiaryButton expanded color={TertiaryButtonColor.GRAY}>
                <CustomLink to={RouteDictionary.WITHDRAW}>
                  Вывести
                </CustomLink>
              </TertiaryButton>
            </div>
            }
            <Navigation/>
          </>}
          <MobileNavigation active={active} onNavigated={() => setActive(false)} />
        </Container>
      </header>
    </div>
  );
};

export default memo(Header);
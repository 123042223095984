export enum RouteDictionary {
    ABOUT_US = "about-us",
    ACTIVE_PROJECTS = "active-projects",
    ARCHIVE = "archived",
    AUTOINVEST = "autoinvest",
    AUTOINVEST_CONFIRMATION = "autoinvest-confirmation",
    AUTOINVEST_DOCUMENTS = "autoinvest-documents",
    BORROWER_INFORMATION = "borrower-information",
    BORROWER_PROJECTS = "borrower-projects",
    CREATE_OFFER = "create-offer",
    DEPOSIT = "deposit",
    DETAILED_PROJECT = "project",
    BORROWER_DETAILED_PROJECT = "borrower-detailed-project",
    DETAILED_TRANSACTION = "transaction",
    DOCUMENTS = "my-documents",
    DOCUMENTS_TO_PROJECT = "documents-to-project",
    ELECTRONIC_DOCUMENTS = "electronic_documents",
    ENTER = "enter",
    ENTER_BORROWER = "enter-borrower",
    FAQ = "faq",
    FUNDRAISING_PROJECTS = "fundraising-projects",
    HOW_IT_WORKS = "how-it-works",
    IDENTIFICATION = "identification",
    IDENTIFICATION_BORROWER = "identification-borrower",
    INDEX = "index",
    INVESTMENT_CONFIRMATION = "investment-confirmation",
    INVESTORS_INFORMATION = "investors-information",
    LOGOUT = "logout",
    MY_ARCHIVE = "my-archive",
    MY_MONEY = "my-money",
    MY_PLATFORM_DOCUMENTS = "my-platform-documents",
    MY_PROJECTS = "my-projects",
    NEWS = "news",
    NOT_FOUND = "not-found",
    NOTIFICATIONS = "notifications",
    OFFER_CONFIRMATION = "offer-confirmation",
    OFFER_DOCUMENTS = "offer-documents",
    OFFER_ECONOMIC_ANALYSIS = "offer-economic-analysis",
    OFFER_LEGAL_ANALYSIS = "offer-legal-analysis",
    OFFER_STAGES = "offers-stages",
    OFFER_STRATEGY = "offer-strategy",
    ORDER_CONFIRMATION = "order-confirmation",
    ORDERS_PROJECTS = "orders-projects",
    OVERDUE_DEBT_REGULATION = "overdue_debt_regulation",
    PAYMENT_SCHEDULE = "payment-schedule",
    PERSONAL_DATA_POLICY = "personal_data_policy",
    PERSONAL_POLICY_ACCEPTED = "personal_policy_accepted",
    PLATFORM = "platform",
    PLATFORM_DOCUMENTS = "platform-documents",
    PRIMARY_MARKET = "primary-market",
    PROFILE = "profile",
    PROFILE_CONTROL = "control",
    PROJECT_DOCUMENTS = "project-documents",
    PROJECT_ECONOMIC_ANALYSIS = "project-economic-analysis",
    PROJECT_LEGAL_ANALYSIS = "project-legal-analysis",
    PROJECT_STAGES = "projects-stages",
    PROJECT_STRATEGY = "project-strategy",
    PUBLIC_DOCUMENTS_ACTUAL = "public-documents-actual",
    PUBLIC_DOCUMENTS_ARCHIVE = "public-documents-archive",
    PURCHASE_CONFIRMATION = "purchase-confirmation",
    PURCHASE_OFFER = "purchase-offer",
    REDIRECT_AFTER_AUTH = "redirect-after-auth",
    REFERRAL = "referral",
    RISK_DECLARATION = "risk_declaration",
    RULES_AUTOINVEST = "rules_autoinvest",
    RULES_REGISTRATION = "rules_registration",
    RULES_SERVICE = "rules_service",
    SECONDARY_MARKET = "secondary-market",
    SIGN_CONTRACT = "sign-contract",
    SURVEY = "survey",
    TRANSACTIONS = "transactions",
    WITHDRAW = "withdraw"
}
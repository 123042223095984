import React, {FC, useState} from "react";
import {
  PaymentType,
  Transaction as TransactionModel,
  TransactionStatus,
  translateTransactionStatus
} from "../../../../api-client";
import {cn, resultIf, RouteDictionary} from "../../../../utils";
import {GridRowHeader} from "../../../layout/grid";
import {Button, CustomLink, Image, Money, PrimaryButton} from "../../../common";
import descriptionClose from "../../../../assets/images/svg/history__arow-t.svg";
import {useErrorCallback, useToggle} from "../../../../hooks";
import './Transaction.scoped.scss';
import {formatDate} from "../../../../formatters";
import {useTransactionsTranslation} from "../../../../hooks/useTransactionsTranslation";
import {MoneyMode} from "../../../common/utils/Money/Money";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {createInvestmentManager} from "../../../../di";

interface Props {
    transaction: TransactionModel
}

const Transaction: FC<Props> = ({transaction}) => {
  const [active, toggle, setActive] = useToggle();
  const [loading, setLoading] = useState(false);
  const handleError = useErrorCallback();
  const translateTransaction = useTransactionsTranslation();
  const isIncome = () => {
    return transaction.paymentType != PaymentType.DEBIT;
  };
  const cancelTransaction = async () => {
    const userConfirmed = window.confirm("Вы действительно хотите отменить инвестицию?");
    if (!userConfirmed) return;

    setLoading(true);
    try {
      if (transaction.objectUuid) {
        const manager = await createInvestmentManager();
        await manager.canselInvestment(transaction.objectUuid);
        alert("Инвестиция успешно отменена");
        window.location.reload();
      }
    } catch (err: any) {
      await handleError(err);
      alert("Ошибка при отмене транзакции");
    } finally {
      setLoading(false);
    }
  };

  return (
    <li
      className={cn(
        "transaction",
        resultIf(active, "active")
      )}
      key={transaction.uuid}
    >
      <CustomLink to={RouteDictionary.DETAILED_PROJECT}
        params={{uuid: transaction.projectUuid}}
        key={transaction.uuid}
        className="transaction__column transaction__debt transactions__link" onClick={toggle}>
        <div className="transaction__text transaction__project">
          {transaction.projectName === undefined ? "Проект не указан" : transaction.projectName[0].text}
        </div>
      </CustomLink>
      <div className="transaction__column transaction__investment" onClick={toggle}>
        <GridRowHeader className="transaction__header">
          ID инвестиции
        </GridRowHeader>
        <div className="transaction__text">
          {transaction.objectCode}
        </div>
      </div>
      <div className="transaction__column transaction__date" onClick={toggle}>
        <GridRowHeader className="transaction__header">
          Дата транзакции
        </GridRowHeader>
        <div className="transaction__text">
          {formatDate(transaction.date)}
        </div>
      </div>
      <div className="transaction__column transaction__type" onClick={toggle}>
        <div className="transaction__text">
          {translateTransaction(transaction.transactionType).text}
        </div>
      </div>
      <div className="transaction__column transaction__money" onClick={toggle}>
        <div className={resultIf(isIncome(), 'transaction--incoming')}>
          <span>
            {isIncome() ? "+" : "-"}
          </span>
          <Money money={transaction.money} mode={resultIf(isIncome(), MoneyMode.WRITE_OFF)}/>
        </div>
      </div>
      <div className="transaction__column transaction__status" onClick={toggle}>
        <div className="transaction__text">
          {translateTransactionStatus(transaction.status)}
        </div>
      </div>
      <div className="transaction__column transaction__description">
        <div>
          <GridRowHeader className="transaction__header">
            Пояснение
          </GridRowHeader>
          <div className="transaction__text">
            {translateTransaction(transaction.transactionType).description}
          </div>
        </div>
        <div className="transaction__description-close" onClick={() => setActive(false)}>
          <Image src={descriptionClose} alt="Close description on mobile"/>
        </div>
      </div>
      {transaction.status === TransactionStatus.PENDING && <div className="transaction__column transaction__cancel" onClick={cancelTransaction}>
        <PrimaryButton className={'transaction__cancel_button'} color={PrimaryButtonColor.GRAY} disabled={loading}>
          <Button>отменить инвестицию</Button>
        </PrimaryButton>
      </div>}
    </li>
  );
};

export default Transaction;
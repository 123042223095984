import React, {useEffect, useState} from "react";
import {Container, Image} from "../../../components";
import {createLegalDocumentProvider} from "../../../di";
import {Collection, LegalDocument, LegalDocumentType} from "../../../api-client";
import {useErrorCallback} from "../../../hooks";
import {DataCollection} from "../../../utils";
import styles from './PublicDocumentsActual.module.scss';
import arrowRight from "../../../assets/images/icons/arrow-right.svg";

const PublicDocumentsActual = () => {
  const [loading, setLoading] = useState(false);
  const [legalDocuments, setLegalDocuments] = useState<Collection<LegalDocument> | null>(null);
  const handleError = useErrorCallback();
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const provider = await createLegalDocumentProvider();
        const results = await provider.getList(LegalDocumentType.DISCLOSURE_ACTUAL);
        const newCollection = new DataCollection<LegalDocument>();
        newCollection.setCollection(results);
        setLegalDocuments(results);
      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);
  return (
    <Container>
      {loading && <div className={styles.text}>Загрузка... </div>}
      <div className={styles.container}>
        {(legalDocuments === null || legalDocuments.items.length === 0) ? <div className={styles.text}> Здесь пока пусто </div>
          : legalDocuments.items.map((document: LegalDocument) => {
            const {title, url} = document;
            return <a className={styles.link} href={url} key={document.uuid}>
              <Image src={arrowRight} className={styles.arrow}/> {title}
            </a>;
          })}
      </div>
    </Container>
  );
};

export default PublicDocumentsActual;
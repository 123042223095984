import React, {FC} from "react";
import {DetailedProject as DetailedProjectModel, isFundRaising, PaymentSchedule, Role} from "api-client";
import "./DetailedProject.scoped.scss";
import {Outlet} from "react-router-dom";
import ProjectMain from "../../pages/detailed-project/ProjectMain";
import DetailedProjectContextProvider from "../../pages/detailed-project/DetailedProjectContext";
import {DetailedProjectContextType} from "../../pages/detailed-project/DetailedProjectContext/DetailedProjectContext";
import ProjectInvest from "../../pages/detailed-project/ProjectInvest";
import {CustomLink, Image} from "../../common";
import {RouteDictionary} from "../../../utils";
import DetailedProjectMobileNavigation from "../../pages/detailed-project/DetailedProjectMobileNavigation";
import arrowBack from "../../../assets/images/svg/header_user-arrow.svg";
import {useProfileData} from "../../../hooks";

type Props = {
    project: DetailedProjectModel
    paymentSchedule: PaymentSchedule | null;
}

const DetailedProject: FC<Props> = ({project, paymentSchedule}) => {
  const {role} = useProfileData();
  const value: DetailedProjectContextType = {
    project: project,
    paymentSchedule: paymentSchedule
  };

  return (
    <DetailedProjectContextProvider props={value}>
      <CustomLink className="detailed-project-back" to={RouteDictionary.PRIMARY_MARKET}>
        <Image src={arrowBack}/>
      </CustomLink>
      <div className="detailed-project">
        <ProjectMain/>
        {isFundRaising(project.status) && role === Role.LENDER && <ProjectInvest/>}
        <div className="detailed-project-outlet">
          <DetailedProjectMobileNavigation/>
          <Outlet/>
        </div>
      </div>
    </DetailedProjectContextProvider>
  );
};

export default DetailedProject;
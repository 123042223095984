import React, {useContext} from "react";
import styles from './ProjectMainStats.module.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {Money} from "../../../common";
import {formatPercents} from "../../../../formatters";

const ProjectMainStats = () => {
  const project = useContext(DetailedProjectContext).project;
  // const paymentSchedule = useContext(DetailedProjectContext).paymentSchedule;

  return (<>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__stats}>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Сумма сбора
          </div>
          <div className={styles.detailedProjectStat__value}>
            <Money money={project.accounting.loan}/>
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Срок займа
          </div>
          <div className={styles.detailedProjectStat__value}>
            {project.accounting.initialTerm} мес.
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Ставка договора
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.interestRate, 0)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Эффективная ставка
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.effectiveInterestRate, 0)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            LTV
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.ltv, 0)}
          </div>
        </div>
      </div>
      <div className={styles.detailedProjectMain__stats}>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            погашение основного долга
          </div>
          <div className={styles.detailedProjectStat__value}>
            ежемесячно
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            погашение процентов
          </div>
          <div className={styles.detailedProjectStat__value}>
            в конце срока
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            назначение
          </div>
          <div className={styles.detailedProjectStat__value}>
            {project.accounting.purpose}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            тип залога
          </div>
          <div className={styles.detailedProjectStat__value}>
            {project.accounting.pledgeType}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            осталось до погашения
          </div>
          <div className={styles.detailedProjectStat__value}>
            - дней
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default ProjectMainStats;
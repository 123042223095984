import React, {AllHTMLAttributes, FC} from "react";
import './IdentificationFileUpload.scoped.scss';
import {cn} from "../../../../utils";
import {createUploadManager} from "../../../../di";
import {UploadedFile} from "../../../../api-client";
import closeImage from "../../../../assets/images/svg/hash-strong_2.svg";
import {Image} from "../../../common";

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    prefix: string;
    text: string;
    files: Array<UploadedFile>;
    onUploaded: (files: Array<UploadedFile>) => void;
}

const IdentificationFileUpload: FC<Props> = ({prefix, text, files, onUploaded, ...props}) => {
  const uploadFiles = async (e: { target: HTMLInputElement }) => {
    try {
      const manager = await createUploadManager();
      const files = (e.target.files as FileList) || [];
      if (files.length === 0) {
        console.error("Файл для загрузки не выбран");
      }
      const uploadedFile = await manager.uploadFile(files[0]);
      onUploaded([uploadedFile]);
    } catch (error: any) {
      if (error.response && error.response.status === 413) {
        console.error("Error: File too large to upload.");
        alert("Выбранный файл слишком велик.");
      } else {
        console.error("Error uploading file:", error);
        alert("Произошла непредвиденная ошибка. Попробуйте снова.");
      }
    }
  };

  //TODO: ADD A REAL REMOVING FILE METHOD
  const removeFile = async () => {
    onUploaded([]);
  };

  const renderFiles = () => {
    if (files.length === 0) {
      return <div className="identification-file-upload__file-placeholder">
        Файлы не выбраны
      </div>;
    }
    const renderedFiles = () => {
      return files.map((file: UploadedFile, index) => {
        return <li className="identification-file-upload__element" key={index}>
          <div className="identification-file-upload__delete" onClick={removeFile}>
            <Image src={closeImage} />
          </div>
          <div>
            { file.name }
          </div>
        </li>;
      });
    };

    return <ul className="identification-file-upload__list">
      { renderedFiles() }
    </ul>;
  };

  return (
    <div {...props} className={cn("identification-file-upload", props.className)}>
      <div className="identification-file-upload__text">
        { text }
      </div>
      <div className="identification-file-upload__block">
        <div className="identification-file-upload__placeholder">
          Перетащите файл сюда или <label htmlFor={`upload-${prefix}`}>выберите файл</label>
        </div>
        <div className="identification-file-upload__file">
          <input id={`upload-${prefix}`} type="file" onChange={uploadFiles} />
          { renderFiles() }
        </div>
      </div>
    </div>
  );
};

export default IdentificationFileUpload;
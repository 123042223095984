import React, {Dispatch, FC, SetStateAction} from "react";
import {isAnnounced, OwnedInvestment as MyInvestment, translateProjectStatus} from "../../../../../api-client";
import {BoldText, CustomLink, Image, Money} from "../../../../common";
import styles from './OwnesInvestment.module.scss';
import {RouteDictionary} from "../../../../../utils";
import calendar from 'assets/images/svg/scheduleLink.svg';
import transaction from 'assets/images/svg/transactions-link.png';
import arrow from 'assets/images/svg/arrow-down-in-circle.png';
import {calculateCollectedPercentage} from "../../../../../utils/pages";
import ProjectProgress from "../../../projects/ProjectProgress";
import {useToggle} from "../../../../../hooks";
import {Money as MoneyModel} from "api-client";
import {formatPercents} from "../../../../../formatters";
import moment from 'moment';
import 'moment/locale/ru';

interface Props {
    investment: MyInvestment;
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
}

const OwnedInvestment: FC<Props> = ({investment, setIsModalActive}) => {
  const [active, toggle] = useToggle();
  if (isAnnounced(investment.project.status)) return null;
  moment.locale('ru');
  const investedSum = new MoneyModel(investment.investments.reduce((sum, inv) => sum + (inv.money.amount || 0), 0), investment.investedSum.currencyCode);
  return (
    <>
      <li className={styles.ownedInvestment}>
        <div className={styles.ownedInvestment__statusContainer}>
          <div className={styles.ownedInvestment__status}>
            {translateProjectStatus(investment.project.status)}
          </div>
        </div>
        <BoldText>
          <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
            key={investment.project.uuid}
            className={styles.ownedInvestment__linkToProject}>
            <div className={styles.ownedInvestment__primary}>
              {investment.project.name[0].text}
            </div>
          </CustomLink>
        </BoldText>
        <div className={styles.ownedInvestment__sum}>
          <div className={styles.ownedInvestment__rowHeader}>
            Инвестиция
          </div>
          <div>
            <Money money={investedSum}/>
          </div>
        </div>

        <div className={styles.ownedInvestment__left}>
          <div className={styles.ownedInvestment__rowHeader}>
            Остаток
          </div>
          <div className={styles.ownedInvestment__leftPercentage}>
            <ProjectProgress
              percentage={calculateCollectedPercentage(investedSum, investment.project.loan)}
              sum={investedSum} isPercent={true}/>
          </div>
        </div>
        <div className={styles.ownedInvestment__yield}>
          <div className={styles.ownedInvestment__rowHeader}>
            Доходность
          </div>
          <div>
            {formatPercents(investment.project.effectiveInterestRate, 0)}
          </div>
        </div>
        <div className={styles.ownedInvestment__percent}>
          <div className={styles.ownedInvestment__rowHeader}>
            Ставка договора
          </div>
          <div>
            {formatPercents(investment.project.interestRate, 0)}
          </div>
        </div>
        <div className={styles.ownedInvestment__term}>
          <div className={styles.ownedInvestment__rowHeader}>
            Срок договора
          </div>
          <div>
            до {moment(investment.project.projectEndDate).format('MMM YYYY')}
          </div>
        </div>
        <Image src={calendar} alt='calendar' className={styles.ownedInvestment__calendar} onClick={() => setIsModalActive(true)}/>
        <CustomLink to={RouteDictionary.TRANSACTIONS} state={{uuid: investment.project.uuid}}  className={styles.ownedInvestment__transaction}>
          <Image src={transaction} alt='transactions' />
        </CustomLink>
        <Image src={arrow} alt='>' className={styles.ownedInvestment__arrow} onClick={toggle} />
      </li>
      {active && investment.investments.map(inv => <li key={inv.uuid} className={styles.ownedInvestment}>
        <div className={styles.ownedInvestment__investmentNumber}>
          Инвестиция #{inv.code}
        </div>
        <div className={styles.ownedInvestment__sum}>
          <div className={styles.ownedInvestment__rowHeader}>
            Инвестиция
          </div>
          <div>
            <Money money={inv.money}/>
          </div>
        </div>

        <div className={styles.ownedInvestment__left}>
          <div className={styles.ownedInvestment__rowHeader}>
            Остаток
          </div>
          <div className={styles.ownedInvestment__leftPercentage}>
            1 000 000,00 ₽
          </div>
        </div>
      </li>)}
    </>

  );
};

export default OwnedInvestment;
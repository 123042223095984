import {ApiClientInterface} from "./clients";
import {EmailExistsResponse, RegistrationInput} from "./types";
import {RegisteredUser} from "./models";

export class RegistrationManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public registerUser(userInput: RegistrationInput): Promise<RegisteredUser> {
    return this.apiClient.post('/user', userInput);
  }

  public borrowerExists(email: string): Promise<EmailExistsResponse> {
    return this.apiClient.get(`/exists/${email}`);
  }
}
export enum TransactionStatus {
    PENDING = "PENDING",
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETED"
}

type TransactionStatusRecord = Record<TransactionStatus, string>;

export const TransactionStatusMapped: TransactionStatusRecord = {
  [TransactionStatus.PENDING]: "Зарезервирована",
  [TransactionStatus.CANCELED]: "Отменена",
  [TransactionStatus.COMPLETED]: "Выполнена"
};

export const translateTransactionStatus = (type: TransactionStatus): string => {
  return TransactionStatusMapped[type];
};
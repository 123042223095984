import React, {useState} from "react";
import {Container, Heading} from "../../../components";
import {HeadingType} from "../../../components/common/typography/headings/Heading/Heading";
import FilterSearch from "../../../components/filters/FilterSearch";
import styles from "./FAQ.module.scss";
import AllQuestions from "./AllQuestions/AllQuestions";

const QUESTION_THEMES = {
  GENERAL: "GENERAL",
  PROJECTS: "PROJECTS",
  LOANS: "LOANS",
  INVESTORS: "INVESTORS",
  BORROWERS: "BORROWERS"
} as const;

type ObjectValues<T> = T[keyof T];
type QuestionTheme = ObjectValues<typeof QUESTION_THEMES>;

const FAQ = () => {
  const [currentTheme, setCurrentTheme] = useState<QuestionTheme>(QUESTION_THEMES.GENERAL);
  const [search, setSearch] = useState('');

  return (
    <Container>
      <Heading headingType={HeadingType.H2}>FAQ</Heading>
      <div className={styles.searchContainer}><FilterSearch search={search} onSearchChange={setSearch}/></div>
      <div className={styles.questionsContainer}>
        <div className={styles.commonQuestions}>
          <div>
            <a
              href="#general"
              className={styles.commonQuestions__link}
              aria-current={currentTheme === QUESTION_THEMES.GENERAL}
              onClick={() => setCurrentTheme(QUESTION_THEMES.GENERAL)}
            >
              Общие вопросы
            </a>
          </div>
          <div>
            <a
              href="#investors"
              className={styles.commonQuestions__link}
              aria-current={currentTheme === QUESTION_THEMES.INVESTORS}
              onClick={() => setCurrentTheme(QUESTION_THEMES.INVESTORS)}
            >
              Инвесторам
            </a>
          </div>
          <div>
            <a
              href="#borrowers"
              className={styles.commonQuestions__link}
              aria-current={currentTheme === QUESTION_THEMES.BORROWERS}
              onClick={() => setCurrentTheme(QUESTION_THEMES.BORROWERS)}
            >
              Заёмщикам
            </a>
          </div>

        </div>
        <div className={styles.allQuestions}><AllQuestions search={search}/></div>
      </div>


    </Container>
  );
};

export default FAQ;
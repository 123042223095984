import React, {useRef, useState} from "react";
import {useEffectOnMount, useNavigateByName, useProfileData, useUserCode} from "../../../../../hooks";
import {
  Block,
  BoldText,
  Container,
  CopyToClipboard,
  Heading,
  Image,
  Money,
  PrimaryHeading,
  Section,
  WithinBlockDecorator
} from "../../../../../components";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import './Deposit.scoped.scss';
import depositWarningImage from '../../../../../assets/images/svg/warning.svg';
import {RouteDictionary} from "../../../../../utils";
import {CSSTransition} from "react-transition-group";
import config from "../../../../../configs/app";
import {QRCodeSVG} from "qrcode.react";
import {Money as MoneyModel} from "../../../../../api-client";
import {MoneyMode} from "../../../../../components/common/utils/Money/Money";

const Deposit = () => {
  const profileData = useProfileData();
  const navigate = useNavigateByName();
  const userCode = useUserCode();
  const [active, setActive] = useState(false);
  const timeoutRef = useRef(0);
  const minSum = new MoneyModel(config.appInvestmentLimit, 'RUB');
  const onHover = () => {
    clearTimeout(timeoutRef.current);
    setActive(true);
  };

  const onLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setActive(false);
    }, 250);
  };
  const purpose = `Пополнение счёта, согласно Агентскому договору № ${profileData.paymentCode}, НДС 0`;
  const getQrCodeString = () => {
    return `ST00012|Name=${config.depositName}` +
            `|BIC=${config.depositBIC}` +
            `|PersonalAcc=${config.depositAccount}` +
            `|BankName=${config.depositBank}` +
            `|PayeeINN=${config.depositINN}` +
            `|KPP=${config.depositKPP}` +
            `|Purpose=${purpose}` +
            `|CorrespAcc=${config.correspAcc}`;
  };
  const canDeposit = () => {
    return profileData.lenderVerified;
  };
  useEffectOnMount(() => {
    console.log(config);
    if (!canDeposit()) {
      navigate(RouteDictionary.MY_MONEY);
      return;
    }
  }
  );
  return (
    <Container>
      <Section className="deposit-section">
        <div className="deposit-container">
          <div className="deposit">
            <PrimaryHeading>
              <Heading headingType={HeadingType.H1}>
                Пополнение счета
              </Heading>
            </PrimaryHeading>
            <div className="deposit__text">
              Пополнение номинального счёта агента осуществляется переводом средств с банковского счёта,
              принадлежащего инвестору
            </div>
            <div className="deposit__data">
              <Block>
                <Block.Content>
                  <Block.Header withPadding>
                    Реквизиты счета
                  </Block.Header>
                  <Block.Body withPadding>
                    <div className="deposit__requisites">
                      <div className="deposit__requisite">
                        <div className="deposit__info">
                          <div className="deposit__heading">
                            № счёта
                          </div>
                          <div>
                            {config.depositAccount}
                          </div>
                        </div>
                        <div>
                          <CopyToClipboard textToCopy={config.depositAccount}/>
                        </div>
                      </div>
                      <div className="deposit__requisite">
                        <div className="deposit__info">
                          <div className="deposit__heading">
                            Банк
                          </div>
                          <div>
                            {config.depositBank}
                          </div>
                        </div>
                        <div>
                          <CopyToClipboard textToCopy={config.depositBank}/>
                        </div>
                      </div>
                      <div className="deposit__requisite">
                        <div className="deposit__info">
                          <div className="deposit__heading">
                            БИК
                          </div>
                          <div>
                            {config.depositBIC}
                          </div>
                        </div>
                        <div>
                          <CopyToClipboard textToCopy={config.depositBIC}/>
                        </div>
                      </div>
                      <div className="deposit__requisite">
                        <div className="deposit__info">
                          <div className="deposit__heading">
                            ИНН
                          </div>
                          <div>
                            {config.depositINN}
                          </div>
                        </div>
                        <div>
                          <CopyToClipboard textToCopy={config.depositINN}/>
                        </div>
                      </div>

                      <div className="deposit__requisite">
                        <div className="deposit__info">
                          <div className="deposit__heading">
                            КПП
                          </div>
                          <div>
                            {config.depositKPP}
                          </div>
                        </div>
                        <div>
                          <CopyToClipboard textToCopy={config.depositKPP}/>
                        </div>
                      </div>

                      <div className="deposit__requisite">
                        <div className="deposit__info">
                          <div className="deposit__heading">
                            Наименование получателя
                          </div>
                          <div>
                            {config.depositName}
                          </div>
                        </div>
                        <div>
                          <CopyToClipboard textToCopy={config.depositName}/>
                        </div>
                      </div>

                      <div className="deposit__requisite">
                        <div className="deposit__info">
                          <div className="deposit__heading">
                            Корр. счет
                          </div>
                          <div>
                            {config.correspAcc}
                          </div>
                        </div>
                        <div>
                          <CopyToClipboard textToCopy={config.correspAcc}/>
                        </div>
                      </div>

                    </div>
                  </Block.Body>
                </Block.Content>
              </Block>
              <div className="deposit-purpose">
                <div className="deposit-purpose__title">
                  <div className="deposit-purpose__heading">
                    Назначение платежа
                  </div>

                  <div
                    className="warning"
                    onMouseEnter={onHover}
                    onMouseLeave={onLeave}
                  >
                    <Image src={depositWarningImage} className="deposit-purpose__image"/>
                    <CSSTransition
                      in={active}
                      timeout={250}
                      classNames="warning__answer-"
                    >
                      <div className="warning__answer">
                        <div className="warning__text">
                          <BoldText>
                            <div>
                              Обратите внимание
                            </div>
                          </BoldText>
                          <div className="warning__min-sum">
                            <span>Минимальная сумма участия в сделке </span>
                            <Money
                              mode={MoneyMode.PENALTY}
                              withPennies={false}
                              money={minSum}
                            />
                          </div>
                        </div>
                      </div>
                    </CSSTransition>
                  </div>
                </div>
                <div className="deposit-purpose__text">
                  При пополнении счёта в назначении платежа напишите свой ID
                </div>
                <div className="deposit-purpose__block">
                  <div className="deposit-purpose__id">
                    Пополнение счёта, <br/>согласно Агентскому договору<br/> № {userCode}, НДС 0
                  </div>
                  <CopyToClipboard textToCopy={userCode}/>
                </div>
              </div>
            </div>
          </div>
          <div className="deposit-qr">
            <PrimaryHeading>
              <Heading headingType={HeadingType.H1}>
                Пополнение по QR-коду
              </Heading>
            </PrimaryHeading>
            <div className="deposit-qr__block">
              <div className="deposit-qr__info">
                <div>
                  QR-код пополнения счёта агента &#34;ИнвестАгент&#34;
                </div>
                <div>
                  Отсканируйте QR-код, данные агента, реквизиты счёта, назначение платежа
                </div>
              </div>
              <div className="deposit-qr__code">
                <WithinBlockDecorator>
                  <QRCodeSVG size={250} level={"L"} value={getQrCodeString()}/>
                </WithinBlockDecorator>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default Deposit;
import React from 'react';
import {BannerInfo, BannerWarning, Container, Section} from "../../../index";
import {useProfileData} from "hooks";
import BannerBlock, {BannerBlockVariant} from "./BannerBlock";
import {Role} from "../../../../api-client";

const AccountNotReadyWarningSection = () => {
  const profileData = useProfileData();
  const {role} = useProfileData();

  const needIdentification = (): boolean => {
    return !profileData.lenderVerified && !profileData.hasIdentificationDraft;
  };

  const hasFirstDeposit = (): boolean => {
    return profileData.hasTransactions;
  };

  return (
    <>
      {(needIdentification() || !hasFirstDeposit()) && <Section>
        <Container>
          {needIdentification() && (role === Role.LENDER) && <BannerWarning>
            <BannerBlock variant={BannerBlockVariant.IDENTIFICATION}/>
          </BannerWarning>}
          {needIdentification() && (role === Role.BORROWER) && <BannerWarning>
            <BannerBlock variant={BannerBlockVariant.IDENTIFICATION_BORROWER}/>
          </BannerWarning>}
          {!needIdentification() && !hasFirstDeposit() && !profileData.lenderVerified &&
          <BannerWarning>
            <BannerBlock variant={BannerBlockVariant.DEPOSIT}/>
          </BannerWarning>
          }
          {profileData.lenderVerified && !hasFirstDeposit() &&
          <BannerInfo>
            <BannerBlock variant={BannerBlockVariant.VERIFIED}/>
          </BannerInfo>
          }
        </Container>
      </Section>}
    </>
  );
};

export default AccountNotReadyWarningSection;
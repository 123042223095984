import React, {useMemo, useState} from "react";
import {Block, Container, Heading, Pagination, PrimaryHeading, Section, Transaction} from "../../../../../components";
import './Transactions.scoped.scss';
import {useErrorCallback, useTransactionsTranslations} from "../../../../../hooks";
import {DataCollection} from "../../../../../utils";
import {Transaction as TransactionModel,} from "../../../../../api-client";
import {createTransactionProvider} from "../../../../../di";
import {Filter} from "../../../../../components/pages/transactions/TransactionsPartialFilter/TransactionsPartialFilter";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import {useLocation} from "react-router-dom";

const Transactions = () => {
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const transactionsTranslations = useTransactionsTranslations();
  const [transactions, setTransactions] =
        useState<DataCollection<TransactionModel>>(new DataCollection());
  const location = useLocation();
  const projectUuid = location.state?.uuid;

  const defaultFilter: Filter = {
    search: "",
    dateFrom: "",
    dateTo: "",
    transactionDirection: null,
    projectUuids: projectUuid? [projectUuid]: []
  };

  const [filter, setFilter] = useState<Filter>(defaultFilter);

  const loadData = async (page: number, perPage: number, filter: Filter) => {
    setLoading(true);
    try {
      const provider = await createTransactionProvider();
      console.log(filter);
      const transactions = await provider.getList(page, perPage, filter);
      const dataCollection = new DataCollection<TransactionModel>();
      dataCollection.setCollection(transactions);
      setTransactions(dataCollection);

    } catch (err: any) {
      await errorCallback(err);
    } finally {
      setLoading(false);
    }
  };

  const transactionsList = useMemo(() => {
    return transactions.items.map((transaction) => {
      return <Transaction transaction={transaction} key={transaction.uuid}/>;
    });
  }, [transactions]);

  return (
    <Section>
      <Container>
        <div className="transactions-filter__header">
          <PrimaryHeading>
            <Heading headingType={HeadingType.H1}>
              История транзакций
            </Heading>
          </PrimaryHeading>
        </div>
        <Pagination
          onLoad={loadData}
          count={transactions.count}
          totalCount={transactions.totalCount}
          filterObject={filter}
          initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
        >
          <Block>
            <Block.Content>
              <Block.Header withPadding>
                <div/>
              </Block.Header>
              <Block.Body className="transactions-body" withPadding={false}>
                <Pagination.Header className="transactions-header">
                  <div className="transactions-header__item">
                    Все проекты
                  </div>
                  <div className="transactions-header__item">
                    ID инвестиции
                  </div>
                  <div className="transactions-header__item">
                    Дата транзакции
                  </div>
                  <div className="transactions-header__item">
                    Тип транзакции
                  </div>
                  <div className="transactions-header__item">
                    Сумма транзакции
                  </div>
                  <div className="transactions-header__item">
                    Статус
                  </div>
                  <div className="transactions-header__item">
                    Пояснение
                  </div>
                  <div className="transactions-header__item"/>
                </Pagination.Header>
                <Pagination.Content loading={loading}>
                  {!transactionsTranslations.loading && <ul className="transactions__list">
                    {transactionsList}
                  </ul>}
                </Pagination.Content>
              </Block.Body>
            </Block.Content>
          </Block>
          <Pagination.Paginator/>
        </Pagination>
      </Container>
    </Section>
  );
};

export default Transactions;
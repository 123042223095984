import React, {FC} from "react";
import {PrimaryMarketProject, translateProjectStatus} from "api-client";
import {BoldText, Image, Money} from "components/common";
import styles from './PrimaryMarketOffer.module.scss';
import {formatPercents} from "../../../../../formatters";
import paymentHistory from 'assets/images/svg/payment-history.svg';

interface Props {
    project: PrimaryMarketProject;
}

const PrimaryMarketOffer: FC<Props> = ({project}) => {
  return (
    <li className={styles.primaryMarketOffer}>
      <div className={styles.primaryMarketOffer__statusContainer}>
        <div className={styles.primaryMarketOffer__status}>
          {translateProjectStatus(project.status)}
        </div>
      </div>
      <BoldText>
        <div className={styles.primaryMarketOffer__primary}>
          {project.name[0].text}
        </div>
      </BoldText>
      <div className={styles.primaryMarketOffer__sum}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Сумма
        </div>
        <div>
          <Money money={project.loan}/>
        </div>
      </div>
      <div className={styles.primaryMarketOffer__available}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Доступно
        </div>
        <div>
          <Money money={project.loan}/>
        </div>
      </div>

      <div className={styles.primaryMarketOffer__yield}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Доходность
        </div>
        <div>
          {formatPercents(28)}
        </div>
      </div>
      <div className={styles.primaryMarketOffer__percent}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Ставка договора
        </div>
        <div>
          {formatPercents(18)}
        </div>
      </div>

      <div className={styles.primaryMarketOffer__term}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Срок договора
        </div>
        <div>
          до янв. 2025
        </div>
      </div>
      <div className={styles.primaryMarketOffer__history}>
        <Image src={paymentHistory} alt='payment'/>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Платежная история
        </div>
      </div>
    </li>
  )
  ;
}
;

export default PrimaryMarketOffer;
import React, {useContext, useEffect, useState} from "react";
import styles from './ProjectMain.module.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {OrderDetails, TransactionType, translateProjectStatus} from "../../../../api-client";
import {Money} from "../../../common";
import {createOrdersProvider} from "../../../../di";
import {useErrorCallback, useLoading} from "../../../../hooks";
import apiClientConfig from "../../../../configs/app";
import {Environment} from "../../../../utils";
import ProjectMainStats from "../ProjectMainStats";
import {formatDate} from "../../../../formatters";

const ProjectMain = () => {
  const project = useContext(DetailedProjectContext).project;
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const [order, setOrder] = useState<OrderDetails>();
  let investmentFeePercent = 0;
  let dividendInterestFeePercent = 0;

  project.feeSettings.map((setting) => {
    if (setting.transactionType === TransactionType.INVESTMENT_FEE && setting.percent) investmentFeePercent = setting.percent;
    if (setting.transactionType === TransactionType.DIVIDEND_INTEREST_FEE && setting.percent) dividendInterestFeePercent = setting.percent;
  });

  useEffect(() => {
    const callback = async () => {
      try {
        const provider = await createOrdersProvider();
        const order = await provider.getOne(project.uuid!);
        setOrder(order);
      } catch (error) {
        if (error instanceof Error) {
          await errorCallback(error);
        }
      }
    };
    (async () => {
      await withLoading(callback);
    })();
  }, []);


  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
        || (apiClientConfig.applicationEnvironment === Environment.test);

  let startDate = null;
  let endDate = null;

  if (project.startedAt) {
    startDate = formatDate(project.startedAt);
  }

  if (project.accounting.maturityDate) {
    endDate = formatDate(new Date(project.accounting.maturityDate));
  }

  if (loading) return <div>Загружаем...</div>;
  return (<>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__top}>
        <div className={styles.detailedProjectMain__status}>
          <div className={styles.detailedProjectMain__code}>
            {project.paymentCode}
          </div>
          <div>
            {translateProjectStatus(project.status)}
          </div>
          <div className={styles.detailedProjectMain__code}>
            Тип: займ
          </div>
        </div>
        <div className={styles.detailedProjectMain__classContainer}>
          <div className={styles.detailedProjectMain__surety}>
            Поручительство
          </div>
          <div className={styles.detailedProjectMain__earlyRepayment}>
            Залог
          </div>
          <div className={styles.detailedProjectMain__rating}>
            Рейтинг AA+BB-
          </div>
        </div>
      </div>
      <div className={styles.detailedProjectMain__project}>
        <div>
          <p className={styles.detailedProjectMain__name}>
            {project.name[0].text}
          </p>
          <p className={styles.detailedProjectMain__info}>
            Срок действия инвестиционного предложения
            с {startDate ? '-' : startDate} до {endDate ? '-' : endDate}
          </p>
        </div>
        {devAndTest && <div>
          <div className={styles.detailedProjectMain__oredrsSum}>
            <span className={styles.detailedProjectMain__oredrsSum__text}>Сумма ваших заявок: </span>
            <Money money={order?.investment || {amount: 0, currencyCode: 'RUB'}}/>
          </div>
        </div>}
      </div>

    </div>
    <ProjectMainStats/>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectAdditional}>
        <div className={styles.detailedProjectAdditional__title}>
          ОБЩЕЕ ОПИСАНИЕ {project.debt.description}
        </div>
      </div>
    </div>

  </>
  );
};

export default ProjectMain;
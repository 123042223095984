import {useMemo} from "react";
import {cn, resultIf} from "../../../../utils";
import {useToggle} from "../../../../hooks";
import styles from './AllQuestions.module.scss';
import {Heading, Image, SecondaryHeading} from "../../../../components";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import React from "react";
import arrowDown from "../../../../assets/images/icons/arrow-down.svg";
import arrowRight from "../../../../assets/images/icons/arrow-right.svg";

export type QuestionModel = {
    title: string;
    content: string;
    id?: string;
}

const generalQuestions: Array<QuestionModel> = [
  {
    title: "Что такое краудлендинг?",
    content: "<p>Это инвестиционная платформа, которая позволяет привлекать инвестиции для бизнеса по договорам займа, объединяет инвесторов и заёмщиков и даёт им возможность заключать сделки напрямую.\n</p>" +
            "<p>Деятельность регулирует 259-ФЗ <a href='https://www.consultant.ru/document/cons_doc_LAW_358753/'>«О привлечении инвестиций с использованием инвестиционных платформ»</a></p>"
  },
  {
    title: "Что такое краудлендинг?",
    content: "<p>Это инвестиционная платформа, которая позволяет привлекать инвестиции для бизнеса по договорам займа, объединяет инвесторов и заёмщиков и даёт им возможность заключать сделки напрямую.\n</p>" +
            "<p>Деятельность регулирует 259-ФЗ <a href='https://www.consultant.ru/document/cons_doc_LAW_358753/'>«О привлечении инвестиций с использованием инвестиционных платформ»</a></p>"
  },
];

const investorQuestions: Array<QuestionModel> = [
  {
    title: "Может ли платформа отказать мне в регистрации?",
    content: "<p>Да. Оператор платформы отдельно рассматривает каждую заявку и может отказать, если данные инвестора покажутся ему недостоверными. А ещё — если заявка не будет соответствовать требованиям 115-ФЗ «О противодействии легализации доходов, полученных преступным путём, и финансированию терроризма».</p>"
  },
];

const borrowerQuestions: Array<QuestionModel> = [
  {
    title: "Какие основные требования к заёмщику?",
    content: "<ul>" +
            "<li>Заёмщик — юрлицо или ИП</li>" +
            "<li>Без ограничений по счёту</li>" +
            "<li>Не банкрот</li>" +
            "<li>Нет сведений о причастности к экстремистской деятельности или терроризму</li>" +
            "<li>Готов предоставить залог — недвижимость, которая соответствует требованиям платформы</li>" +
            "</ul>"
  },
];

type AllQuestionsType = {
    general: Array<QuestionModel>;
    lender: Array<QuestionModel>;
    borrower: Array<QuestionModel>;
}

type Props = {
    search: string;
}

const AllQuestions = ({search}: Props) => {
  const filteredQuestions = useMemo((): AllQuestionsType => {
    return {
      general: generalQuestions.filter(item => item.title.includes(search)),
      lender: investorQuestions.filter(item => item.title.includes(search)),
      borrower: borrowerQuestions.filter(item => item.title.includes(search)),
    };
  }, [search]);
  const noQuestionsFound = () => {
    return filteredQuestions.general.length === 0
            && filteredQuestions.lender.length === 0
            && filteredQuestions.borrower.length === 0;
  };
  const renderQuestions = (questions: Array<QuestionModel>) => {
    return questions.map(question => <QuestionItem question={question} key={question.id} />);
  };
  
  return (
    <div className={styles.all_questions} >
      { noQuestionsFound() ? <div className={styles.all_questions__placeholder}>
        По вашему запросу ничего не найдено.
      </div> : <>
        { filteredQuestions.general.length !== 0 && <div className={styles.all_questions_container}>
          <div className={styles.all_questions__pixel} id="general" />
          <SecondaryHeading>
            <Heading headingType={HeadingType.H2} className={styles.all_questions__heading}>
              Общие вопросы
            </Heading>
          </SecondaryHeading>
          <ul className={styles.all_questions__list}>
            { renderQuestions(filteredQuestions.general) }
          </ul>
        </div> }
        { filteredQuestions.lender.length !== 0 && <div className={styles.all_questions_container}>
          <div className={styles.all_questions__pixel} id="investors" />
          <SecondaryHeading>
            <Heading headingType={HeadingType.H2} className={styles.all_questions__heading}>
              Инвесторам
            </Heading>
          </SecondaryHeading>
          <ul className={styles.all_questions__list}>
            { renderQuestions(filteredQuestions.lender) }
          </ul>
        </div> }
        { filteredQuestions.borrower.length !== 0 && <div className={styles.all_questions_container}>
          <div className={styles.all_questions__pixel} id="borrowers" />
          <SecondaryHeading>
            <Heading headingType={HeadingType.H2} className={styles.all_questions__heading}>
              Заёмщикам
            </Heading>
          </SecondaryHeading>
          <ul className={styles.all_questions__list}>
            { renderQuestions(filteredQuestions.borrower) }
          </ul>
        </div> }
      </> }
    </div>
  );
};

export default AllQuestions;

const QuestionItem = ({question} : {
    question: QuestionModel;
}) => {
  const [active, toggle] = useToggle();
  return (<div className={cn(styles.question, resultIf(active, styles.question___active))}>
    <header className={styles.question__header} id={question.id}>
      <button
        aria-expanded={active}
        onClick={toggle}
        className={styles.question__button}
      >
        <div className={styles.question__title}>
          {question.title}
        </div>
        <Image className={styles.question__icon}
          src={active ? arrowDown : arrowRight}/>
      </button>
    </header>
    <div className={styles.question__content}>
      <div dangerouslySetInnerHTML={{__html: question.content}}
        className={styles.question__content_inner}></div>
    </div>
  </div>);
};

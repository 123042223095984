import React, {useState} from "react";
import {useEffectOnMount, useProfileData} from "../../../../hooks";
import {
  Identification as ApiIdentification,
  LenderType,
  translateLenderType,
  UuidResult,
} from "../../../../api-client";
import './Identification.scoped.scss';
import {
  ColoredText,
  Container,
  CustomLink,
  DashboardHeader,
  Heading,
  PrimaryHeading,
  Section,
  VerificationConfirmationModal,
  VerificationSMSModal
} from "../../../../components";
import {useDispatch} from "react-redux";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import IdentificationTypeChanger from "../../../../components/user/identification/IdentificationTypeChanger";
import {IdentificationIndividual, IdentificationLegalEntity} from "../../../../components/user/identification";
import {RouteDictionary} from "../../../../utils";
import {TextColor} from "../../../../components/common/typography/texts/ColoredText/ColoredText";
import {createIdentificationManager} from "../../../../di";
import {useSearchParams} from "react-router-dom";
import {setIdentificationDraft} from "../../../../store/profile";

const Identification = () => {
  const profileData = useProfileData();
  const dispatch = useDispatch();
  const [type, setType] = useState(LenderType.INDIVIDUAL);
  const [_, setSearchParams] = useSearchParams();
  const [identification, setIdentification] = useState<ApiIdentification | undefined>(undefined);
  const isVerified = () => {
    return profileData.lenderVerified;
  };
  const [smsModalOpened, setSmsModalOpened] = useState(false);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  useEffectOnMount(() => {
    if (isVerified()) {
      if (profileData.isLegalEntity) setType(LenderType.LEGAL_ENTITY);
      const callback = async () => {
        try {
          const provider = await createIdentificationManager();
          const identification = await provider.findOne();
          setIdentification(identification);
        } catch (e: any) {
          console.log(e.message);
        }
      };
      callback();
    }
  });

  const handleSubmit = (uuid: UuidResult) => {
    dispatch(setIdentificationDraft(true));
    setSearchParams({uuid: uuid.uuid});
    setSmsModalOpened(true);
    return;
  };

  return (
    <Container>
      <Section className="identification-section">
        <DashboardHeader>
          <DashboardHeader.Main>
            <PrimaryHeading>
              <Heading headingType={HeadingType.H1}>
                {isVerified() ? 'Изменение данных' : 'Верификация инвестора'}
              </Heading>
            </PrimaryHeading>
            <div>
              <ColoredText color={TextColor.PRIMARY_GREEN}>
                {isVerified() ?
                  <CustomLink to={RouteDictionary.PROFILE_CONTROL}>
                    Назад
                  </CustomLink> :
                  <CustomLink to={RouteDictionary.MY_MONEY}>
                    Пропустить
                  </CustomLink>}
              </ColoredText>
            </div>
          </DashboardHeader.Main>
        </DashboardHeader>
        <div>
          {!isVerified() && <div className="identification-type-select">
            <IdentificationTypeChanger
              active={type === LenderType.INDIVIDUAL || type === LenderType.ENTREPRENEUR}
              onClick={() => {
                if (type === LenderType.ENTREPRENEUR)
                  setType(LenderType.INDIVIDUAL); 
                else setType(LenderType.ENTREPRENEUR);
              }}
            >
              <div className="identification-type-select--PC">
                {translateLenderType(LenderType.INDIVIDUAL)}
              </div>
              <div className="identification-type-select--mobile">
                Физ. лицо / ИП
              </div>
            </IdentificationTypeChanger>
            <IdentificationTypeChanger
              active={type === LenderType.LEGAL_ENTITY}
              onClick={() => setType(LenderType.LEGAL_ENTITY)}
            >
              <div className="identification-type-select--PC">
                {translateLenderType(LenderType.LEGAL_ENTITY)}
              </div>
              <div className="identification-type-select--mobile">
                Юр. лицо
              </div>
            </IdentificationTypeChanger>
          </div>}
          {(!isVerified() || identification) && <div className="identification-blocks">
            {(type === LenderType.INDIVIDUAL || type === LenderType.ENTREPRENEUR) &&
            <IdentificationIndividual onSubmit={handleSubmit} identification={identification} type={type} setType={setType}/>}
            {type === LenderType.LEGAL_ENTITY &&
            <IdentificationLegalEntity onSubmit={handleSubmit} identification={identification}/>}
          </div>}
        </div>
      </Section>

      <VerificationSMSModal
        active={smsModalOpened}
        onClose={() => setSmsModalOpened(false)}
        setConfirmationModalOpened={setConfirmationModalOpened}
      />
      <VerificationConfirmationModal
        active={confirmationModalOpened}
        onClose={() => setConfirmationModalOpened(false)}
        isLegalEntity={type === LenderType.LEGAL_ENTITY}
      />
    </Container>
  );
};

export default Identification;
import React, {FC} from "react";
import Modal from "../Modal";
import {ModalProps} from "../Modal/Modal";
import completed from 'assets/images/complited.png';
import partial from 'assets/images/partial.png';
import overdue from 'assets/images/svg/overdue.svg';
import processing from 'assets/images/svg/processing.svg';
import upcoming from 'assets/images/svg/upcoming.svg';
import styles from "./PaymentScheduleModal.module.scss";
import {Image} from "../../images";
interface Props extends ModalProps {
}

export const PaymentScheduleModal: FC<Props> = ({active, onClose}) => {
  const data = [
    {date: "01.01.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "paid", executionSize: "1 500 000,00 ₽", delay: "0 дней"},
    {date: "01.02.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "paid", executionSize: "1 500 000,00 ₽", delay: "11 дней"},
    {date: "01.03.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "paid", executionSize: "1 500 000,00 ₽", delay: "25 дней"},
    {date: "01.04.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "partial", executionSize: "1 500 000,00 ₽", delay: "45 дней"},
    {date: "01.05.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "overdue", executionSize: "1 500 000,00 ₽", delay: "15 дней"},
    {date: "01.06.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "processing", executionSize: "0,00 ₽", delay: "1 день"},
    {date: "01.07.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "upcoming", executionSize: "0,00 ₽", delay: "0 дней"},
    {date: "01.08.2024", principal: "1 500 000,00 ₽", interest: "0,00 ₽", status: "upcoming", executionSize: "0,00 ₽", delay: "0 дней"},
  ];
  const renderStatus = (status: any) => {
    switch (status) {
    case "processing": return <Image src={processing} alt="processing" />;
    case "paid": return <Image src={completed} alt="completed" />;
    case "partial": return <Image src={partial} alt="partial" />;
    case "overdue": return <Image src={overdue} alt="overdue" />;
    case "upcoming": return <Image src={upcoming} alt="upcoming" />;
    }
  };
  return (
    <Modal active={active} onClose={onClose} className={styles.container}>
      <Modal.Close/>
      <div className={styles.paymentSchedule}>
        <header className={styles.paymentSchedule__header}>
          <div className={styles.paymentSchedule__headerProjectInfo}>
            <p className={styles.paymentSchedule__headerProjectInfoTitle}>Данные проекта:
              <span className={styles.paymentSchedule__headerProjectInfoName}> АО Большевичка ID</span>
              <span className={styles.paymentSchedule__headerProjectInfoID}>#285081</span></p>
            <p className={styles.paymentSchedule__headerProjectInfoTitle}>Данные заемщика: <span
              className={styles.paymentSchedule__headerProjectInfoName}>ООО Рога и копыта / ИНН 57620943 </span>
            </p>
          </div>
          <div className={styles.paymentSchedule__headerContainer}>
            <p className={styles.paymentSchedule__headerTitle}>График выплат по договору <span>#390260</span></p>
            <p className={styles.paymentSchedule__headerExecution}>Исполнение графика </p>
          </div>
        </header>

        <div className={styles.paymentSchedule__schedule}>
          <table className={styles.paymentSchedule__scheduleTable}>
            <thead>
              <tr>
                <th></th>
                <th>Дата</th>
                <th>Основной долг</th>
                <th>Процент</th>
                <th>Статус</th>
                <th>Размер исполнения</th>
                <th>Просрочка</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    {renderStatus(item.status)}
                  </td>
                  <td>{item.date}</td>
                  <td>{item.principal}</td>
                  <td>{item.interest}</td>
                  <td>{item.status}</td>
                  <td>{item.executionSize}</td>
                  <td>{item.delay}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.paymentSchedule__footer}>
          <p className={styles.paymentSchedule__footerTitle}>Общий размер штрафных % на 16.05.2024</p>
          <p className={styles.paymentSchedule__footerSum}>1 290 000,00 ₽</p>
        </div>
      </div>
    </Modal>
  );
};
import {ProjectsCount} from "../models/project/ProjectsCount";

export enum ProjectStatus {
    NEW = "NEW",
    PENDING = "PENDING",
    ORDERS = "ORDERS",
    FUND_RAISING = "FUND_RAISING",
    FINISHED = "FINISHED",
    LOAN_PAYMENT = "LOAN_PAYMENT",
    PAID = "PAID",
    REFUNDED = "REFUNDED",
    FINAL_REVIEW = "FINAL_REVIEW",
    AUTO_INVESTMENT = "AUTO_INVESTMENT",
    REVIEW_APPROVED = "REVIEW_APPROVED",
    FINAL_REVIEW_APPROVED = "FINAL_REVIEW_APPROVED",
}

type ProjectStatusRecord = Record<ProjectStatus, string>;

export const ProjectStatusMapped: ProjectStatusRecord = {
  [ProjectStatus.NEW]: "Новый проект",
  [ProjectStatus.PENDING]: "Ожидание...",
  [ProjectStatus.ORDERS]: "Заявка подана",
  [ProjectStatus.FUND_RAISING]: "Сбор средств",
  [ProjectStatus.FINISHED]: "Сбор средств",
  [ProjectStatus.AUTO_INVESTMENT]: "Анонсирован",
  [ProjectStatus.LOAN_PAYMENT]: "В графике",
  [ProjectStatus.PAID]: "Завершен",
  [ProjectStatus.REFUNDED]: "Отменен",
  [ProjectStatus.FINAL_REVIEW]: "Анонсирован",
  [ProjectStatus.REVIEW_APPROVED]: "Анонсирован",
  [ProjectStatus.FINAL_REVIEW_APPROVED]: "Анонсирован",
};
export type StatusKey = 'Сбор средств' | 'В графике' | 'Завершен';
export type StatusKeyMyInvestments = 'Сбор средств' | 'В графике' | 'Завершен';

export const statusInFastFilter: Record<StatusKey, ProjectStatus[]>  = {
  'Сбор средств': [ProjectStatus.FUND_RAISING, ProjectStatus.FINISHED],
  'В графике': [ProjectStatus.LOAN_PAYMENT],
  'Завершен': [ProjectStatus.PAID]
};

export const statusInFastFilterMyInvestments: Record<StatusKeyMyInvestments, ProjectStatus[]>  = {
  'Сбор средств': [ProjectStatus.FUND_RAISING, ProjectStatus.FINISHED],
  'В графике': [ProjectStatus.LOAN_PAYMENT],
  'Завершен': [ProjectStatus.PAID],
};

export const statusCount: Record<StatusKey, keyof ProjectsCount>  = {
  'Сбор средств': "fundRaising",
  'В графике': "current",
  'Завершен': "closed"
};
export const translateProjectStatus = (type: ProjectStatus): string => {
  return ProjectStatusMapped[type];
};

export const isAnnounced = (status: ProjectStatus): boolean => {
  return status === ProjectStatus.AUTO_INVESTMENT || status === ProjectStatus.FINAL_REVIEW_APPROVED || status === ProjectStatus.FINAL_REVIEW;
};

export const isFundRaising = (status: ProjectStatus): boolean => {
  return status === ProjectStatus.FUND_RAISING || status === ProjectStatus.FINISHED;
};

export const isLoanPayment = (status: ProjectStatus): boolean => {
  return status === ProjectStatus.LOAN_PAYMENT;
};

export const isPaid = (status: ProjectStatus): boolean => {
  return status === ProjectStatus.PAID;
};

export const isRefunded = (status: ProjectStatus): boolean => {
  return status === ProjectStatus.REFUNDED;
};
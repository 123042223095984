import {Dispatch, SetStateAction, useState} from "react";
import {Phone} from "../../api-client";

export interface LegalEntityDataContext {
  jobPosition: string;
  setJobPosition: Dispatch<SetStateAction<string>>;
  innLegalEntity: string;
  setInnLegalEntity: Dispatch<SetStateAction<string>>;
  phone: Phone;
  setPhone: Dispatch<SetStateAction<Phone>>;
}

export const useLegalEntityData = () => {
  const [jobPosition, setJobPosition] = useState("");
  const [innLegalEntity, setInnLegalEntity] = useState("");
  const [phone, setPhone] = useState(new Phone('+7', ''));
  return {
    jobPosition,
    setJobPosition,
    innLegalEntity,
    setInnLegalEntity,
    phone,
    setPhone,
  };
};
import React, {FC} from "react";
import {OwnedProject} from "api-client";
import {BoldText, Money} from "components/common";
import styles from './MyOwnedProject.module.scss';
import {formatPercents} from "../../../../formatters";

interface Props {
    project: OwnedProject;
}

const MyOwnedProject: FC<Props> = ({project}) => {
  return (
    <li className={styles.myOwnedProject}>
      <BoldText>
        <div className={styles.myOwnedProject__primary}>
          {project.name[0].text}
        </div>
      </BoldText>
      <div className={styles.myOwnedProject__sum}>
        <div className={styles.myOwnedProject__rowHeader}>
          Сумма сбора
        </div>
        <div>
          <Money money={project.investedSum}/>
        </div>
      </div>

      <div className={styles.myOwnedProject__forecast}>
        <div className={styles.myOwnedProject__rowHeader}>
          Ставка договора
        </div>
        <div>
          {formatPercents(18)}
        </div>
      </div>

      <div className={styles.myOwnedProject__term}>
        <div className={styles.myOwnedProject__rowHeader}>
          Срок займа
        </div>
        <div>
          {project.initialTerm.toFixed(2)} мес.
        </div>
      </div>
      <div className={styles.myOwnedProject__statusContainer}>
        <div className={styles.myOwnedProject__rowHeader}/>
      </div>
    </li>
  )
  ;
}
;

export default MyOwnedProject;